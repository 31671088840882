

import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import "./index.css"

interface PieChartProps {
    // Define your props here
}

const PieChart: React.FC<PieChartProps> = (props) => {
    const data = useSelector((state: any) => state.api.getSubmission?.data)
    const [apexChartData, setApexChartData] = useState<any>({})
    const [apexChartData2, setApexChartData2] = useState<any>({})
    const [apexChartData3, setApexChartData3] = useState<any>({})
    const [apexChartData4, setApexChartData4] = useState<any>({})
    const [isChapter, setIsChapter] = useState<boolean>(false)
    const [isTopic, setTopic] = useState<boolean>(false)
    const [isDifficulty, setIsDifficulty] = useState<boolean>(false)
    const [subjectName, setSubjectName] = useState<string>("")

    const color:string[] = ['#75d8a6', '#ff7783', '#6c757d']

    const clickTopic = (name: string) => {
        setIsDifficulty(true)
        const chapterData = data.data.filter((item: any) => item.topic === name)
        const levelData = chapterData.map((item: any) => {
            item.level = item.difficulty
            return item
        })
        if (data?.data?.length) {
            const subjects = Array.from(new Set(levelData.map((item: any) => item.level)));
            const subjectStats: any = subjects.reduce((acc: any, subject: any) => {
                acc[subject] = { easy: 0, medium: 0, high: 0 };
                return acc;
            }, {});
            levelData.forEach((item: any) => {
                if (item.level === "Easy") {
                    subjectStats[item.level].easy++;
                } else if (item.level === "Medium") {
                    subjectStats[item.level].medium++;
                } else if (item.level === "High") {
                    subjectStats[item.level].high++;
                }
            });

            const chartData: any = {
                series: [
                    {
                        name: 'Easy',
                        data: subjects.map((subject: any) => subjectStats[subject].easy),
                    },
                    {
                        name: 'Medium',
                        data: subjects.map((subject: any) => subjectStats[subject].medium)
                    },
                    {
                        name: 'Hard',
                        data: subjects.map((subject: any) => subjectStats[subject].high)
                    }
                ],
                options: {
                    chart: {
                        type: 'bar',
                        height: 350,
                        stacked: true,
                    },
                    plotOptions: {
                        bar: {
                            barHeight: "50px",
                            horizontal: true,
                            dataLabels: {
                                total: {
                                    enabled: true,
                                    offsetX: 0,
                                    style: {
                                        fontSize: '13px',
                                        fontWeight: 900
                                    }
                                }
                            }
                        }
                    },
                    stroke: {
                        width: 1,
                        colors: ['#fff']
                    },
                    title: {
                        text: name
                    },
                    colors: ["#17a2b8" , "#ffc107" , "#6610f2"],
                     
                    xaxis: {
                        categories: subjects,
                        labels: {
                            formatter: function (val: any) {
                                return val;
                            }
                        }
                    },
                    yaxis: {
                        title: {
                            text: undefined
                        }
                    },
                    tooltip: {
                        y: {
                            formatter: function (val: any) {
                                return val;
                            }
                        },

                    },
                    fill: {
                        opacity: 1
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'left',
                        offsetX: 40
                    }
                }
            };
            setApexChartData4(chartData)
        }
        difficultyChartFunction(name, "topic")
    }
    const clickChapter = (name: string) => {
        setTopic(true)
        const chapterData = data.data.filter((item: any) => item.chapter === name)
        if (data?.data?.length) {
            const subjects = Array.from(new Set(chapterData.map((item: any) => item.topic)));
            const subjectStats: any = subjects.reduce((acc: any, subject: any) => {
                acc[subject] = { correct: 0, wrong: 0, notAttempted: 0 };
                return acc;
            }, {});
            chapterData.forEach((item: any) => {
                if (item.selectedOption === null) {
                    subjectStats[item.topic].notAttempted++;
                } else if (item.selectedOption === item.correctOption) {
                    subjectStats[item.topic].correct++;
                } else {
                    subjectStats[item.topic].wrong++;
                }
            });

            const chartData: any = {
                series: [
                    {
                        name: 'Correct',
                        data: subjects.map((subject: any) => subjectStats[subject].correct),
                    },
                    {
                        name: 'Wrong',
                        data: subjects.map((subject: any) => subjectStats[subject].wrong)
                    },
                    {
                        name: 'Not Attempted',
                        data: subjects.map((subject: any) => subjectStats[subject].notAttempted)
                    }
                ],
                options: {
                    chart: {
                        type: 'bar',
                        height: 350,
                        stacked: true,
                        events: {
                            dataPointSelection: (event: any, chartContext: any, config: any) => {
                                clickTopic(config.w.config.xaxis.categories[config.dataPointIndex])
                            }
                        }
                    },
                    plotOptions: {
                        bar: {
                            barHeight: "50px",
                            horizontal: true,
                            dataLabels: {
                                total: {
                                    enabled: true,
                                    offsetX: 0,
                                    style: {
                                        fontSize: '13px',
                                        fontWeight: 900
                                    }
                                }
                            }
                        }
                    },
                    stroke: {
                        width: 1,
                        colors: ['#fff']
                    },
                    title: {
                        text: name
                    },
                    colors: color,
                     
                    xaxis: {
                        categories: subjects,
                        labels: {
                            formatter: function (val: any) {
                                return val;
                            }
                        }
                    },
                    yaxis: {
                        title: {
                            text: undefined
                        }
                    },
                    tooltip: {
                        y: {
                            formatter: function (val: any) {
                                return val;
                            }
                        },

                    },
                    fill: {
                        opacity: 1
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'left',
                        offsetX: 40
                    }
                }
            };
            setApexChartData3(chartData)
            difficultyChartFunction(name, "chapter")
        }
    }
    const clickSubject = (name: string) => {
        setIsChapter(true)
        setTopic(false)
        setSubjectName(name)
        const chapterData = data.data.filter((item: any) => item.subject === name)
        if (data?.data?.length) {

            const submissionData = JSON.parse(JSON.stringify(data.data))
            const subjects = Array.from(new Set(chapterData.map((item: any) => item.chapter)));


            const subjectStats: any = subjects.reduce((acc: any, subject: any) => {
                acc[subject] = { correct: 0, wrong: 0, notAttempted: 0 };
                return acc;
            }, {});

            chapterData.forEach((item: any) => {
                if (item.selectedOption === null) {
                    subjectStats[item.chapter].notAttempted++;
                } else if (item.selectedOption === item.correctOption) {
                    subjectStats[item.chapter].correct++;
                } else {
                    subjectStats[item.chapter].wrong++;
                }
            });
            const chartData: any = {
                series: [
                    {
                        name: 'Correct',
                        data: subjects.map((subject: any) => subjectStats[subject].correct)
                    },
                    {
                        name: 'Wrong',
                        data: subjects.map((subject: any) => subjectStats[subject].wrong)
                    },
                    {
                        name: 'Not Attempted',
                        data: subjects.map((subject: any) => subjectStats[subject].notAttempted)
                    }
                ],
                options: {
                    chart: {
                        type: 'bar',
                        height: 350,
                        stacked: true,
                        events: {
                            dataPointSelection: (event: any, chartContext: any, config: any) => {
                                clickChapter(config.w.config.xaxis.categories[config.dataPointIndex])
                            }
                        }
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            barHeight: "50px",
                            dataLabels: {
                                total: {
                                    enabled: true,
                                    offsetX: 0,
                                    style: {
                                        fontSize: '13px',
                                        fontWeight: 900
                                    }
                                }
                            }
                        }
                    },
                    stroke: {
                        width: 1,
                        colors: ['#fff']
                    },
                    title: {
                        text: name
                    },
                    colors: color,
                     
                    xaxis: {
                        categories: subjects,
                        labels: {
                            formatter: function (val: any) {
                                return val;
                            }
                        }
                    },
                    yaxis: {
                        title: {
                            text: undefined
                        }
                    },
                    tooltip: {
                        y: {
                            formatter: function (val: any) {
                                return val;
                            }
                        },

                    },
                    fill: {
                        opacity: 1
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'left',
                        offsetX: 40
                    }
                }
            };
            setApexChartData2(chartData)
            difficultyChartFunction(name, "subject")
        }
    }

    const difficultyChartFunction = (name: string, topic: string) => {
        const subName = data.data[0][topic]
        const chapterData = data.data.filter((item: any) => item[topic].toLowerCase() === subName.toLowerCase())
        const levelData = chapterData.map((item: any) => {
            item.level = item.difficulty
            return item
        })
        if (data?.data?.length) {
            const subjects = Array.from(new Set(levelData.map((item: any) => item.level)));
            const subjectStats: any = subjects.reduce((acc: any, subject: any) => {
                acc[subject] = { easy: 0, medium: 0, high: 0 };
                return acc;
            }, {});
            levelData.forEach((item: any) => {
                if (item.level === "Easy") {
                    subjectStats[item.level].easy++;
                } else if (item.level === "Medium") {
                    subjectStats[item.level].medium++;
                } else if (item.level === "High") {
                    subjectStats[item.level].high++;
                }
            });

            const chartData: any = {
                series: [
                    {
                        name: 'Easy',
                        data: subjects.map((subject: any) => subjectStats[subject].easy),
                    },
                    {
                        name: 'Medium',
                        data: subjects.map((subject: any) => subjectStats[subject].medium)
                    },
                    {
                        name: 'High',
                        data: subjects.map((subject: any) => subjectStats[subject].high)
                    }
                ],
                options: {
                    chart: {
                        type: 'bar',
                        height: 350,
                        stacked: true,
                    },
                    plotOptions: {
                        bar: {
                            barHeight: "50px",
                            horizontal: true,
                            dataLabels: {
                                total: {
                                    enabled: true,
                                    offsetX: 0,
                                    style: {
                                        fontSize: '13px',
                                        fontWeight: 900
                                    }
                                }
                            }
                        }
                    },
                    stroke: {
                        width: 1,
                        colors: ['#fff']
                    },
                    title: {
                        text: `Difficulty : ${name}`
                    },
                    colors: color,
                     
                    xaxis: {
                        categories: subjects,
                        labels: {
                            formatter: function (val: any) {
                                return val;
                            }
                        }
                    },
                    yaxis: {
                        title: {
                            text: undefined
                        }
                    },
                    tooltip: {
                        y: {
                            formatter: function (val: any) {
                                return val;
                            }
                        },

                    },
                    fill: {
                        opacity: 1
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'left',
                        offsetX: 40
                    }
                }
            };
            setApexChartData4(chartData)
            setIsDifficulty(true)
        }
    }


    useEffect(() => {

        if (data?.data?.length) {
            const submissionData = JSON.parse(JSON.stringify(data.data))
            const subjects = Array.from(new Set(submissionData.map((item: any) => item.subject)));
            const subjectStats: any = subjects.reduce((acc: any, subject: any) => {
                acc[subject] = { correct: 0, wrong: 0, notAttempted: 0 };
                return acc;
            }, {});
            submissionData.forEach((item: any) => {
                if (item.selectedOption === null) {
                    subjectStats[item.subject].notAttempted++;
                } else if (item.selectedOption === item.correctOption) {
                    subjectStats[item.subject].correct++;
                } else {
                    subjectStats[item.subject].wrong++;
                }
            });

            const chartData: any = {
                series: [
                    {
                        name: 'Correct',
                        data: subjects.map((subject: any) => subjectStats[subject].correct)
                    },
                    {
                        name: 'Wrong',
                        data: subjects.map((subject: any) => subjectStats[subject].wrong)
                    },
                    {
                        name: 'Not Attempted',
                        data: subjects.map((subject: any) => subjectStats[subject].notAttempted)
                    }
                ],
                options: {
                    chart: {
                        type: 'bar',
                        height: 350,
                        stacked: true,
                        events: {
                            dataPointSelection: (event: any, chartContext: any, config: any) => {
                                clickSubject(config.w.config.xaxis.categories[config.dataPointIndex])
                            }
                        }
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                            barHeight: "50px",
                            dataLabels: {
                                total: {
                                    enabled: true,
                                    offsetX: 0,
                                    style: {
                                        fontSize: '13px',
                                        fontWeight: 900
                                    }
                                }
                            }
                        }
                    },

                    stroke: {
                        width: 1,
                        colors: ['#fff']
                    },
                    title: {
                        text: 'Report card'
                    },
                    colors: color,
                     
                    xaxis: {
                        categories: subjects,
                        labels: {
                            formatter: function (val: any) {
                                return val;
                            }
                        }
                    },
                    yaxis: {
                        title: {
                            text: undefined
                        }
                    },
                    tooltip: {
                        y: {
                            formatter: function (val: any) {
                                return val;
                            }
                        },

                    },
                    fill: {
                        opacity: 1
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'left',
                        offsetX: 40
                    }
                }
            };
            setApexChartData(chartData)
            difficultyChartFunction("Physics", "subject")
        }
    }, [data?.data])
    const heights = 410

    const setHeight = (array: any) => {
        if (array?.series) {
            if (array?.series[0].data.length <= 4) {
                return `${heights}px`
            } else {
                const data = array?.series[0].data.length - 4
                const returnData = (data * 60) + heights
                return `${returnData}px`
            }
        } else {
            return `${heights}px`
        }
    }

    return (
        <div className='chart_body'>
            {apexChartData?.options && apexChartData?.series ?
                <>
                    {isDifficulty ?
                        <div style={{ minHeight: setHeight(apexChartData4) }}>
                            <ReactApexChart height={setHeight(apexChartData4)} className="submission_chart" options={apexChartData4?.options} series={apexChartData4?.series} type="bar" />
                        </div>
                        : ""}
                    <div style={{ minHeight: setHeight(apexChartData) }}>
                        <ReactApexChart height={setHeight(apexChartData)} className="submission_chart" options={apexChartData?.options} series={apexChartData?.series} type="bar" />
                    </div>
                    {isChapter ?
                        <div style={{ minHeight: setHeight(apexChartData2) }}>
                            <ReactApexChart height={setHeight(apexChartData2)} className="submission_chart" options={apexChartData2?.options} series={apexChartData2?.series} type="bar" />
                        </div>
                        : ""}
                    {isTopic ?
                        <div style={{ minHeight: setHeight(apexChartData3) }}>
                            <ReactApexChart height={setHeight(apexChartData3)} className="submission_chart" options={apexChartData3?.options} series={apexChartData3?.series} type="bar" />
                        </div>
                        : ""}
                </>

                : ""}
            {/* <div id="html-dist"></div> */}
        </div>
    );
};

export default PieChart;