/* eslint-disable linebreak-style */
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { setStorageFunction } from "./utils/CommonFunction";
// import { initializeApp } from "firebase/app";
// import { getMessaging , getToken, onMessage} from "firebase/messaging";

const settings = { timestampsInSnapshots: true, merge: true };

const firebaseConfig = {
  apiKey: "AIzaSyCDPYcUsYIGxFtW_WtkLuLw0x0yknYqoHs",
  authDomain: "webodhi-chat-demo.firebaseapp.com",
  databaseURL: "https://webodhi-chat-demo-default-rtdb.firebaseio.com",
  projectId: "webodhi-chat-demo",
  storageBucket: "webodhi-chat-demo.appspot.com",
  messagingSenderId: "365227347668",
  appId: "1:365227347668:web:a87e4b16053633e4263541",
  measurementId: "G-88LWM3TJ8R"
};
firebase.initializeApp(firebaseConfig);
const firebaseApp = firebase.initializeApp(firebaseConfig);
// const firebaseApp = initializeApp(config);
firebase.firestore().settings(settings);
const messaging = getMessaging(firebaseApp);
export const getTokenCustom = () => {
  return getToken(messaging, { vapidKey: "BMBfistSQdkwKlHCO3x6P0vs94fGS0DQ6pZSV2FaAjRi2e8Nb0Lhy3_k_PDjZDHlAhWcSXuSkJf3u_6fVP_4pyI" }).then((currentToken: any) => {
    if (currentToken) {
      setStorageFunction("clientToken", currentToken)
    } else {
      localStorage.removeItem("clientToken");
    }
  }).catch((err) => {
    console.log("An error occurred while retrieving token. ", err);
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });


getTokenCustom()
export default firebase;