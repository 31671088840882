import { notification } from "antd";
import moment from "moment";
import CryptoJS from 'crypto-js';



export const notifications = (status,
  message) => {
  return notification[status]({
    message: status,
    description: message,
    placement: "topRight",
    duration: 2.5
  });

}

export const sortingMethod = (array, key) => {
  array?.sort(function (a, b) {
    if (a[key].toLowerCase() < b[key].toLowerCase()) {
      return -1;
    }
    if (a[key].toLowerCase() > b[key].toLowerCase()) {
      return 1;
    }
    return 0;
  })
  return array
}
export const getRandomColor = (name) => {
  let text
  if (name) {
    text = name
  } else {
    text = "a"
  }
  // get first alphabet in upper case
  const firstAlphabet = text.charAt(0).toLowerCase();

  // get the ASCII code of the character
  const asciiCode = firstAlphabet.charCodeAt(0);

  // number that contains 3 times ASCII value of character -- unique for every alphabet
  const colorNum =
    asciiCode.toString() + asciiCode.toString() + asciiCode.toString();

  var num = Math.round(0xffffff * parseInt(colorNum));
  var r = (num >> 16) & 255;
  var g = (num >> 8) & 255;
  var b = num & 255;

  return {
    color: "rgb(" + r + ", " + g + ", " + b + ", 0.3)",
    character: firstAlphabet.toUpperCase(),
  };
};

export const timeFormat = (date) => {
  const dateObject = new Date(date);
  const formattedDate = dateObject.getFullYear() + '-' +
    ('0' + (dateObject.getMonth() + 1)).slice(-2) + '-' +
    ('0' + dateObject.getDate()).slice(-2);
  return formattedDate
}


export const openNotification = (
  placement,
  message,
  type,
  status

) => {
  notification[status ? status : "success"]({
    message: type,
    description: message,
    placement,
  });
};
export const downloadTemplate = async () => {
  const excelFilePath = require('../assets/bulk_login.xlsx');
  fetch(excelFilePath)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'template-file.xlsx';
      a.click();
      window.URL.revokeObjectURL(url);
    })

    .catch((error) => {
      console.error('Error downloading the Excel file:', error);
    });
}
export const getDateAndMonth = () => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var today = new Date();
  let date = null;
  var dd = String(today.getDate()).padStart(2, "0");
  // var mm = String(today.getMonth() + 1).padStart(2, "0");
  // var yyyy = today.getFullYear();
  date = monthNames[today.getMonth()] + ", " + dd
  return date
};
// =======
//     return array
// }

export const autoSelectOption = (data) => {
  if ((data[0] === "a" || data[0] === "<p>a</p>") && (data[1] === "b" || data[1] === "<p>b</p>") && (data[2] === "c" || data[2] === "<p>c</p>") && (data[3] === "d" || data[3] === "<p>d</p>")) {
    return "text"
  } else if ((data[0] === "1" || data[0] === "<p>1</p>") && (data[1] === "2" || data[1] === "<p>2</p>") && (data[2] === "3" || data[2] === "<p>3</p>") && (data[3] === "4" || data[3] === "<p>4</p>")) {
    return "number"
  } else {
    return ""
  }
}

export const answerIndex = (data, answer) => {
  const currentIndex = data?.findIndex(item => item === answer)
  return currentIndex
}

export const convertTimeStamp = (date, time) => {

  let datePara
  if (typeof date === "object") {
    datePara = moment(date).format("YYYY-MM-DD")
  } else {
    const dateString = date;

    const formatToCheck = 'D/M/YYYY';

    const parsedDate = moment(dateString, formatToCheck, true);
    const isValidFormat = parsedDate.isValid() && parsedDate.format(formatToCheck) === dateString;
    if (isValidFormat) {
      const dateF = moment(dateString, formatToCheck);
      const formattedDate = dateF.format('YYYY-MM-DD');
      datePara = formattedDate

    } else {
      datePara = date
    }
  }
  const [year, month, day] = datePara.split('-');
  const formattedDateString = `${month}/${day}/${year}`;

  // Combine date and time strings
  const combinedDateTimeString = `${formattedDateString} ${time}`;

  // Parse the combined date and time string
  const combinedDateTime = new Date(combinedDateTimeString);

  // Get the timestamp in milliseconds
  const timestamp = combinedDateTime.getTime();
  return timestamp
}

export const convertStamptoDate = (timestamp, addedMinutes) => {
  const addTime = addedMinutes ? addedMinutes : 0
  const data = new Date(+timestamp);
  const addedStampData = data.setMinutes(data.getMinutes() + (+addTime))
  const date = new Date(addedStampData)
  // Set the time zone to Indian Standard Time (IST)
  const options = {
    timeZone: 'Asia/Kolkata',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true, // Use 12-hour clock format
  };

  const indianTime = date.toLocaleString('en-IN', options);
  return indianTime
}

export const handleButtonClick = async () => {
  try {
    const clipboardItems = await navigator.clipboard.read();
    for (const item of clipboardItems) {
      if (item.types.includes('image/png')) {
        const blob = await item.getType('image/png');
        const imageUrl = URL.createObjectURL(blob);
        //   setPastedImage(imageUrl);

        return imageUrl
      }
    }
  } catch (error) {
    console.error('Error reading clipboard data:', error);
  }
};
export const getClassnameById = (id, array) => {
  const dataArray = []
  const data = array.map((item) => {
    const value = item.classes.filter((val) => val.classId === id)
    if (value.length) {
      dataArray.push(value[0])
    }
  })
  if (dataArray.length) {
    return dataArray[0].classGrade
  } else {
    return ""
  }
}

export const getAnswerFromScreen = (format, text) => {
  const available_formats = { A: ["A", "B", "C", "D"], "a": ["a", "b", "c", "d"], "1": ["1", "2", "3", "4"] }
  text = text.replace(/©/g, match => {
    return format.includes('C') || format.includes('A') || format.includes('B') || format.includes('D') ? 'C' : 'c';
  });

  let keyss = []
  for (let i = 0; i < format.length; i++) {
    let value = available_formats[format[i]]
    if (value) {
      for (let j = 0; j < value.length; j++) {
        let key = format.replace(format[i], value[j])
        keyss.push(key)
      }
      break
    }
  }
  let answers = []
  for (let i = 0; i < keyss.length; i++) {
    let position = text.indexOf(keyss[i])
    if (position >= 0) {
      answers.push(position)
    }
  }

  answers.push(text.length)
  const anwserText = []
  for (let i = 0; i < (answers.length - 1); i++) {
    let answer = text.slice(answers[i], answers[i + 1])
    const answer1 = answer.replace("<p>", "").replace("</p>", "")
    anwserText.push(answer1)
  }
  let question = text.slice(0, (answers[0]))
  return {
    question: question,
    answers: anwserText
  }
}

export const dateANdTimeInIso = (text) => {

  // Parse the date using Moment.js
  const momentDate = moment(text, "DD/M/YYYY, h:mm:ss a");

  // Format the date in ISO 8601 format
  const isoDateString = momentDate.toISOString();

  return isoDateString
}
export const timeDiffrence = (date) => {
  const givenDate = new Date(date);

  const currentDate = new Date();

  const timeDiff = givenDate - currentDate;
  return timeDiff
}

export const indianTime = (data) => {
  const utcDate = new Date(data);

  // Get the UTC timestamp in milliseconds
  const utcTimestamp = utcDate.getTime();

  // UTC to IST offset (in milliseconds)
  const istOffset = 5.5 * 60 * 60 * 1000; // India is UTC+5.5 hours

  // Convert UTC timestamp to IST timestamp
  const istTimestamp = utcTimestamp + istOffset;

  // Create a new Date object with the IST timestamp
  const istDate = new Date(istTimestamp);
  return istDate

}
// >>>>>>> dpk/login-changes


export const matchedArray = (array1, array2, matchVal, addKey) => {
  array1.forEach((option) => {
    if (array2.includes(option[matchVal])) {
      option[addKey] = true;
    } else {
      option[addKey] = false;
    }
  });
  return array1
}

export const addSelectedKey = (array) => {
  array.map((item) => {
    item.selected = false
    return item
  })
  return array
}

export const returnMatchedArray = (data, data2) => {
  const flattenUnique = arr => [...new Set(arr.flat())];

  const mergeClassIds = (data, ids) => {
    let mergedClassIds = [];
    data.forEach(item => {
      if (ids.includes(item.departmentId)) {
        mergedClassIds = mergedClassIds.concat(item.classes);
      }
    });
    return flattenUnique(mergedClassIds);
  };

  const result = mergeClassIds(data, data2);
  return result
}

export const returnSelectedArray = (array, returnKey) => {

  const selectedItems = array.filter(item => item.selected);
  const selectedClasses = selectedItems.map(item => item[returnKey]).flat();
  return selectedClasses;


}

export const addedDeletedData = (arrayWithMatch, arrayToMatch) => {
  const data = arrayWithMatch?.filter((element) => !(arrayToMatch.includes(element)));
  return data;

}


export const hasCountGreaterThanZero = (data) => {
  return Object.values(data).some(item => item.count > 0);
};

export function getNetworkStatus() {
  let isOnline = navigator.onLine;

  const checkOnlineStatus = async () => {
    try {
      const online = await fetch('https://jsonplaceholder.typicode.com/posts/1')
        .then(response => response.ok)
        .catch(() => false);
      isOnline = online;
    } catch (error) {
      isOnline = false;
    }
  };

  const handleOnline = () => checkOnlineStatus();
  const handleOffline = () => isOnline = false;

  window.addEventListener('online', handleOnline);
  window.addEventListener('offline', handleOffline);

  checkOnlineStatus();

  return isOnline;
}

export const dateFormat = (date) => {
  const formatedData = moment(date).format("DD-MM-YYYY");
  return formatedData
}

export const updateArray = (key, value, array, index) => {
  array[index][key] = value
  return array
}

export const encryption = (text) => {
  const data = btoa(text)
  return data
}
export const decryption = (text) => {
  const data = atob(text)
  return data
}

export const setStorageFunction = (key, value) => {
  try {
    localStorage.setItem(key, value)
  } catch (e) {
    if (e.code === 22 || e.name === "QuotaExceededError") {
      console.warn("Storage quota exceeded. Clearing local storage...");
      clearCache();
      try {
        localStorage.setItem(key, value)
      } catch (err) {
        console.error("Failed after clearing storage: ", err);
      }
    } else {
      console.error("Unexpected storage error: ", e);
    }
  }
}
const clearCache = () => {
  localStorage.removeItem("submitData")
  localStorage.removeItem("scoreboard")
  localStorage.removeItem("offlineData")
}

export const fileListToBase64 = (data) => {
  const reader = new FileReader();
  reader.onload = () => {
    const base64String = reader.result.split(',')[1];
    // Store the Base64 string in localStorage
    setStorageFunction('fileData', base64String);
  };
  reader.readAsDataURL(data);
}

export const isBase64Url = (url) => {
  if (url.startsWith('data:') && url.includes(';base64,')) {
    const base64Part = url.split(';base64,')[1];
    try {
      const decoded = atob(base64Part);
      const reencoded = btoa(decoded);
      if (reencoded === base64Part) {
        return true;
      }
    } catch (error) {
      return false;
    }
  }
  return false;
}
export const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const checkAi = (data) => {
  if (data === "AI") {
    return 1
  } else {
    return 0
  }
}
function getIdFromText(text) {
  return parseInt(text.split('. ')[0], 10);
}

export const convertSubjectData = (input) => {
  const output = [];

  if (input) {
    Object.keys(input).forEach(mainKey => {
      const mainId = getIdFromText(mainKey);
      const mainName = mainKey.split('. ')[1];
      const mainData = {
        id: mainId,
        name: mainName,
        data: []
      };

      Object.keys(input[mainKey]).forEach(subKey => {
        const subId = getIdFromText(subKey);
        const subName = subKey.split('. ')[1];
        const subData = {
          id: subId,
          name: subName,
          data: input[mainKey][subKey].map(item => {
            const itemId = getIdFromText(item);
            const itemName = item.split('. ')[1];
            return {
              id: itemId,
              name: itemName
            };
          })
        };

        mainData.data.push(subData);
      });

      output.push(mainData);
    });
  }

  return output;

}

export const setSubjsValues = (id, obj) => {
  if (obj && obj.length) {
    const data = obj.filter((item => item.id === id))
    return data[0]
  }
}
export const setChapsValues = (id, cId, obj) => {

  if (obj && obj.length) {
    const currentIndex = obj.findIndex((item => item.id === id))
    if (currentIndex >= 0) {
      const data = obj[currentIndex].data.filter((item => item.id === cId))
      return data[0]
    }
  }
}
export const setTopsValues = (id, cId, tId, obj) => {

  if (obj && obj.length) {
    const currentIndex = obj.findIndex((item => item.id === id))
    if (currentIndex >= 0) {
      const chepterIndex = obj[currentIndex].data.findIndex((item) => item.id === cId)
      if (chepterIndex >= 0) {
        const data = obj[currentIndex].data[chepterIndex].data.filter((item => item.id === tId))
        return data[0]
      }
    }
  }
}

export const generateKey = () => {
  return CryptoJS.PBKDF2("webodhi", CryptoJS.lib.WordArray.random(128 / 8), {
    keySize: 256 / 32
  }).toString();
};

// Encrypt function
export const encryptText = (plainText) => {
  // const cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from("my-key"), crypto.randomBytes(16));
  // let encrypted = cipher.update(plainText);
  // encrypted = Buffer.concat([encrypted, cipher.final()]);
  // console.log("encrypted.toString('hex')encrypted.toString('hex')" , encrypted.toString('hex'))
  // return encrypted.toString('hex');
  const key = generateKey()
  return CryptoJS.AES.encrypt(plainText, "my-key").toString()
};

// Decrypt function
export const decryptText = (text) => {
  const key = generateKey()
  const bytes = CryptoJS.AES.decrypt(text, "my-key");
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedData;
};



export const dandTInTimestamp = (text) => {
  const dateObject = new Date(text);
  const timestamp = dateObject.getTime();
  return timestamp

}

export const timestampToDateandTime = (timestamp) => {

  const dateObject = new Date(timestamp);
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true, // Set to false for 24-hour format
    timeZone: 'Asia/Kolkata'
  };

  const formattedDateTime = dateObject.toLocaleString('en-IN', options);
  const main = formattedDateTime.split(",")
  return {
    date: main[0],
    time: main[1]
  }
}

export const uniqueArrayNewSet = (arr, name) => {
  const uniqueIds = new Set(); // Create a Set to track unique IDs
  return arr.filter(item => {
    // Check if the ID is already in the Set
    if (!uniqueIds.has(item[name])) {
      uniqueIds.add(item[name]); // Add the ID to the Set if it's not present
      return true; // Keep this item in the filtered array
    }
    return false; // Filter out this item
  });
}


export function doBBoxesOverlap(bbox1, bbox2) {
  return !(bbox2.x > bbox1.x + bbox1.width ||
    bbox2.x + bbox2.width < bbox1.x ||
    bbox2.y > bbox1.y + bbox1.height ||
    bbox2.y + bbox2.height < bbox1.y);
}

export function mergeBBoxes(bbox1, bbox2) {
  const x1 = Math.min(bbox1.x, bbox2.x);
  const y1 = Math.min(bbox1.y, bbox2.y);
  const x2 = Math.max(bbox1.x + bbox1.width, bbox2.x + bbox2.width);
  const y2 = Math.max(bbox1.y + bbox1.height, bbox2.y + bbox2.height);

  return {
    x: x1,
    y: y1,
    width: x2 - x1,
    height: y2 - y1,
    x0: x1,
    y0: y1,
    x1: x2,
    y1: y2,
    text: bbox1.hasOwnProperty('text') ? bbox1?.text : (bbox2.hasOwnProperty('text') ? bbox2.text : ""),
    isText: true,
    isImg: false,
  };
}



// export function mergeOverlappingBBoxes(bboxes, threshold) {
//   let mergedBBoxes= [];

//   for (const bbox of bboxes) {
//       let merged = false;

//       for (let i = 0; i < mergedBBoxes.length; i++) {
//           if (doBBoxesOverlap(mergedBBoxes[i], bbox)) {
//               mergedBBoxes[i] = mergeBBoxes(mergedBBoxes[i], bbox);
//               merged = true;
//               break;
//           }
//       }

//       if (!merged) {
//           mergedBBoxes.push(bbox);
//       }
//   }

//   // Remove small bounding boxes based on the threshold
//   mergedBBoxes = mergedBBoxes.filter(bbox =>
//       bbox.width >= threshold && bbox.height >= threshold
//   );

//   return mergedBBoxes;
// }

export const optionIndex = (name) => {
  const option = ["a", "b", "c", "d"]
  return option.indexOf(name)


}


