import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 1000,
    headers: { 'X-Custom-Header': 'foobar' }
});

export const postAPiCall = async (url: string, data: any, auth?: any) => {
    const token = localStorage.getItem("jwt")
    const headers = {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: auth ? auth : token,
        },
    }
    try {
        const response = await instance.post(url, JSON.stringify(data), headers)
        return response
    } catch (error: any) {
        console.log(error)
        return error
    }
}


export const fetchPost = async (path: string, data: any, authToken?: any) => {
    const token = localStorage.getItem("jwt")

    const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: "application/json",
            Authorization: authToken ? `${authToken}` : (token ? token : ""),
        },
        body: JSON.stringify(data),
    });
    const responseData = await response.json();
    return responseData
}
export const fetchGet = async (path: string,) => {
    const token = localStorage.getItem("jwt")

    const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: "application/json",
            Authorization: token ? token : "",
        },
    });

    if (response.ok) {
        const data = await response.json();
        return data
    }
}

export const axiosInstance = instance;